import React, { useEffect } from 'react';
import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';

const ThreeText = () => {
    useEffect(() => {
        const vertexShader = `
      attribute float size;
      attribute vec3 customColor;
      varying vec3 vColor;

      void main() {
        vColor = customColor;
        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
        gl_PointSize = size;
        gl_Position = projectionMatrix * mvPosition;
      }
    `;

        const fragmentShader = `
      uniform vec3 color;
      uniform sampler2D pointTexture;
      varying vec3 vColor;

      void main() {
        gl_FragColor = vec4(color * vColor, 1.0);
        gl_FragColor = gl_FragColor * texture2D(pointTexture, gl_PointCoord);
      }
    `;

        const preload = () => {
            let typo = null;
            const manager = new THREE.LoadingManager();

            manager.onLoad = function () {
                if (typo && particle) {
                    const environment = new Environment(typo, particle);
                }
            };

            const loader = new FontLoader(manager);
            loader.load(
                'https://res.cloudinary.com/dydre7amr/raw/upload/v1612950355/font_zsd4dr.json',
                (font) => {
                    typo = font;
                    if (typo && particle) {
                        const environment = new Environment(typo, particle);
                    }
                }
            );

            const particle = new THREE.TextureLoader(manager).load(
                'https://res.cloudinary.com/dfvtkoboz/image/upload/v1605013866/particle_a64uzf.png',
                () => {
                    if (typo && particle) {
                        const environment = new Environment(typo, particle);
                    }
                }
            );
        };

        if (document.readyState === 'complete' || (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
            preload();
        } else {
            document.addEventListener('DOMContentLoaded', preload);
        }

        class Environment {
            constructor(font, particle) {
                this.font = font;
                this.particle = particle;
                this.container = document.querySelector('#magic');
                this.scene = new THREE.Scene();
                this.createCamera();
                this.createRenderer();
                this.setup();
                this.bindEvents();
            }

            bindEvents() {
                window.addEventListener('resize', this.onWindowResize.bind(this));
            }

            setup() {
                this.createParticles = new CreateParticles(
                    this.scene,
                    this.font,
                    this.particle,
                    this.camera,
                    this.renderer
                );
            }

            render() {
                if (this.createParticles) {
                    this.createParticles.render();
                }
            }

            createCamera() {
                const isMobile = window.innerWidth <= 768;
                const aspectRatio = this.container.clientWidth / this.container.clientHeight;
            
                this.camera = new THREE.PerspectiveCamera(
                    isMobile ? 75 : 65, // Use higher FOV on mobile
                    aspectRatio,
                    1,
                    10000
                );
                
                this.camera.position.set(0, 0, isMobile ? 350 : 200); // Adjust z-position for mobile
            }
            
            createRenderer() {
                this.renderer = new THREE.WebGLRenderer({ alpha: true });
                this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
                this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
            
                window.addEventListener('resize', () => {
                    this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
                    this.camera.aspect = this.container.clientWidth / this.container.clientHeight;
                    this.camera.updateProjectionMatrix();
                });
            
                this.container.appendChild(this.renderer.domElement);
                this.renderer.setAnimationLoop(() => {
                    this.render();
                });
            }

            onWindowResize() {
                const isMobile = window.innerWidth <= 768;
                this.camera.fov = isMobile ? 75 : 65;
                this.camera.position.set(0, 0, isMobile ? 300 : 200);
            
                this.camera.updateProjectionMatrix();
                this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
            }            

        }

        class CreateParticles {
            constructor(scene, font, particleImg, camera, renderer) {
                this.scene = scene;
                this.font = font;
                this.particleImg = particleImg;
                this.camera = camera;
                this.renderer = renderer;

                // Define isMobile locally here
                const isMobile = window.innerWidth <= 768;

                // Initialize raycaster and mouse vector
                this.raycaster = new THREE.Raycaster();
                this.mouse = new THREE.Vector2(-200, 200);  // Initially place mouse far off screen

                // Initialize color for dynamic changes
                this.colorChange = new THREE.Color();

                // Initialize particle interaction variables
                this.buttom = false;

                // Particle data settings
                this.data = {
                    text: 'THE\nJOURNEY\nBEGINS\nHERE',
                    amount: 1500,
                    particleSize: isMobile ? 2 : 1,  // Larger particles on mobile
                    particleColor: 0xffffff,
                    textSize: isMobile ? 65 : 14,  // Larger text on mobile for better readability
                    area: isMobile ? 150 : 250, // Smaller area for faster response on mobile
                    ease: isMobile ? 0.08 : 0.05, // Increase ease on mobile for faster movement
                  };
                  

                this.setup();
                this.bindEvents();
            }

            setup() {
                const geometry = new THREE.PlaneGeometry(1600, 600);  // Adjust to the text area's approximate size
                const material = new THREE.MeshBasicMaterial({ color: 0x00ff00, transparent: true });
                this.planeArea = new THREE.Mesh(geometry, material);
                this.planeArea.visible = false;
                this.createText();  // Create text particles
            }

            bindEvents() {
                document.addEventListener('mousedown', this.onMouseDown.bind(this));
                document.addEventListener('mousemove', this.onMouseMove.bind(this));
                document.addEventListener('mouseup', this.onMouseUp.bind(this));
                document.addEventListener('mouseleave', this.onMouseLeave.bind(this));  // Handle mouse leaving the window

                document.addEventListener('touchstart', this.onTouchStart.bind(this));
                document.addEventListener('touchmove', this.onTouchMove.bind(this));
                document.addEventListener('touchend', this.onTouchEnd.bind(this));
            }

            onMouseDown(event) {
                this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
                this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

                const vector = new THREE.Vector3(this.mouse.x, this.mouse.y, 0.5);
                vector.unproject(this.camera);
                const dir = vector.sub(this.camera.position).normalize();
                const distance = -this.camera.position.z / dir.z;
                this.currenPosition = this.camera.position.clone().add(dir.multiplyScalar(distance));

                // Set a higher ease for faster initial interaction
                this.buttom = true;
                this.data.ease = window.innerWidth <= 768 ? 0.1 : 0.02; // Faster particle reaction on mobile
            }

            onMouseUp() {
                this.buttom = false;
                this.data.ease = window.innerWidth <= 768 ? 0.08 : 0.05; // Reset easing after interaction
            }

            onMouseMove(event) {
                const rect = this.renderer.domElement.getBoundingClientRect();

                this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
                this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

                // Unproject the mouse position to 3D space
                const vector = new THREE.Vector3(this.mouse.x, this.mouse.y, 0.5);
                vector.unproject(this.camera);
                const dir = vector.sub(this.camera.position).normalize();
                const distance = -this.camera.position.z / dir.z;
                this.currenPosition = this.camera.position.clone().add(dir.multiplyScalar(distance));
            }

            onMouseLeave() {
                // Move the mouse off-screen to stop interactions
                this.mouse.x = -200;
                this.mouse.y = 200;

                // Reset easing so particles return smoothly
                this.buttom = false;  // Ensures particles go back to normal behavior
                this.data.ease = 0.05;  // Smooth transition

                // Force an update to trigger particle position updates
                this.createParticles.render();  // Ensure render still happens when mouse leaves
            }

            onTouchStart(event) {
                if (event.touches.length === 1) {  // Ensure it's a single touch
                  const touch = event.touches[0];
                  const rect = this.renderer.domElement.getBoundingClientRect();  // Get the canvas' bounding box
              
                  // Calculate normalized touch coordinates relative to the canvas
                  this.mouse.x = ((touch.clientX - rect.left) / rect.width) * 2 - 1;
                  this.mouse.y = -((touch.clientY - rect.top) / rect.height) * 2 + 1;
              
                  // Trigger the same behavior as mouse down
                  this.onMouseDown(touch);
                }
              }

              onTouchEnd(event) {
                // Trigger the same behavior as mouse up
                this.onMouseUp(event);
              }

              onTouchMove(event) {
                if (event.touches.length === 1) {  // Single touch only
                  const touch = event.touches[0];
                  const rect = this.renderer.domElement.getBoundingClientRect();
              
                  // Calculate normalized touch coordinates relative to the canvas
                  this.mouse.x = ((touch.clientX - rect.left) / rect.width) * 2 - 1;
                  this.mouse.y = -((touch.clientY - rect.top) / rect.height) * 2 + 1;
              
                  // Trigger the same behavior as mouse move
                  this.onMouseMove(touch);
                }
              }


            render() {
                const time = ((0.001 * performance.now()) % 12) / 12;
                const zigzagTime = (1 + Math.sin(time * 2 * Math.PI)) / 6;

                this.raycaster.setFromCamera(this.mouse, this.camera);
                const intersects = this.raycaster.intersectObject(this.planeArea);

                if (intersects.length > 0) {
                    const pos = this.particles.geometry.attributes.position;
                    const copy = this.geometryCopy.attributes.position;
                    const colors = this.particles.geometry.attributes.customColor;
                    const size = this.particles.geometry.attributes.size;

                    const mx = intersects[0].point.x;
                    const my = intersects[0].point.y;
                    const mz = intersects[0].point.z;

                    for (let i = 0, l = pos.count; i < l; i++) {
                        const initX = copy.getX(i);
                        const initY = copy.getY(i);
                        const initZ = copy.getZ(i);

                        let px = pos.getX(i);
                        let py = pos.getY(i);
                        let pz = pos.getZ(i);

                        this.colorChange.setHSL(0.5, 1, 1);
                        colors.setXYZ(i, this.colorChange.r, this.colorChange.g, this.colorChange.b);
                        colors.needsUpdate = true;

                        size.array[i] = this.data.particleSize;
                        size.needsUpdate = true;

                        const dx = mx - px;
                        const dy = my - py;
                        const dz = mz - pz;

                        const mouseDistance = this.distance(mx, my, px, py);
                        const d = (dx * dx + dy * dy);
                        const force = -this.data.area / d;

                        if (this.buttom) {
                            const t = Math.atan2(dy, dx);
                            px -= force * Math.cos(t);
                            py -= force * Math.sin(t);

                            this.colorChange.setHSL(0.5 + zigzagTime, 1.0, 0.5);
                            colors.setXYZ(i, this.colorChange.r, this.colorChange.g, this.colorChange.b);
                            colors.needsUpdate = true;

                            // Ensure fast particle motion on initial click
                            this.data.ease = 0.02;  // Increase the ease when mouse is down

                        } else if (mouseDistance < this.data.area) {
                            if (i % 5 === 0) {
                                const t = Math.atan2(dy, dx);
                                px -= 0.03 * Math.cos(t);
                                py -= 0.03 * Math.sin(t);

                                this.colorChange.setHSL(0.15, 1.0, 0.5);
                                colors.setXYZ(i, this.colorChange.r, this.colorChange.g, this.colorChange.b);
                                colors.needsUpdate = true;

                                size.array[i] = this.data.particleSize / 1.2;
                                size.needsUpdate = true;

                            } else {
                                const t = Math.atan2(dy, dx);
                                px += force * Math.cos(t);
                                py += force * Math.sin(t);

                                pos.setXYZ(i, px, py, pz);
                                pos.needsUpdate = true;

                                size.array[i] = this.data.particleSize * 1.5;  // Increase size on click
                                size.needsUpdate = true;
                            }

                            if (Math.abs(px - initX) > 10 || Math.abs(py - initY) > 10) {
                                this.colorChange.setHSL(0.15, 1.0, 0.5);
                                colors.setXYZ(i, this.colorChange.r, this.colorChange.g, this.colorChange.b);
                                colors.needsUpdate = true;

                                size.array[i] = this.data.particleSize / 1.8;
                                size.needsUpdate = true;
                            }
                        }

                        // Return particles to initial position with easing
                        px += (initX - px) * this.data.ease;
                        py += (initY - py) * this.data.ease;
                        pz += (initZ - pz) * this.data.ease;

                        pos.setXYZ(i, px, py, pz);
                        pos.needsUpdate = true;
                    }
                }

                this.renderer.render(this.scene, this.camera);
            }

            createText() {
                let thePoints = [];
                let shapes = this.font.generateShapes(this.data.text, this.data.textSize);
                let geometry = new THREE.ShapeGeometry(shapes);
                geometry.computeBoundingBox();

                const xMid = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);
                const yMid = (geometry.boundingBox.max.y - geometry.boundingBox.min.y) / 2.85;
                geometry.center();

                let colors = [];
                let sizes = [];

                for (let x = 0; x < shapes.length; x++) {
                    let shape = shapes[x];
                    const amountPoints = shape.type === 'Path' ? this.data.amount / 2 : this.data.amount;
                    let points = shape.getSpacedPoints(amountPoints);

                    points.forEach((element) => {
                        const a = new THREE.Vector3(element.x, element.y, 0);
                        thePoints.push(a);

                        this.colorChange.set(0xffffff);
                        colors.push(this.colorChange.r, this.colorChange.g, this.colorChange.b);
                        sizes.push(1);
                    });
                }

                let geoParticles = new THREE.BufferGeometry().setFromPoints(thePoints);
                geoParticles.translate(xMid, yMid, 0);

                geoParticles.setAttribute('customColor', new THREE.Float32BufferAttribute(colors, 3));
                geoParticles.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1));

                const material = new THREE.ShaderMaterial({
                    uniforms: {
                        color: { value: new THREE.Color(0xffffff) },
                        pointTexture: { value: this.particleImg },
                    },
                    vertexShader: document.getElementById('vertexshader').textContent,
                    fragmentShader: document.getElementById('fragmentshader').textContent,
                    blending: THREE.AdditiveBlending,
                    depthTest: false,
                    transparent: true,
                });

                this.particles = new THREE.Points(geoParticles, material);
                this.scene.add(this.particles);

                // Now initialize geometryCopy
                this.geometryCopy = new THREE.BufferGeometry();
                this.geometryCopy.copy(this.particles.geometry);  // Copy geometry for future reference
            }

            visibleHeightAtZDepth(depth, camera) {
                const cameraOffset = camera.position.z;
                if (depth < cameraOffset) depth -= cameraOffset;
                else depth += cameraOffset;

                const vFOV = (camera.fov * Math.PI) / 180;
                return 2 * Math.tan(vFOV / 2) * Math.abs(depth);
            }

            visibleWidthAtZDepth(depth, camera) {
                const height = this.visibleHeightAtZDepth(depth, camera);
                return height * camera.aspect;
            }

            distance(x1, y1, x2, y2) {
                return Math.sqrt(Math.pow((x1 - x2), 2) + Math.pow((y1 - y2), 2));
            }
        }


        return () => {
            const container = document.querySelector('#magic');
            if (container) {
                container.innerHTML = ''; // Clean up the canvas properly
            }
        };
    }, []);

    return <div id="magic"></div>;
};

export default ThreeText;
