import React, { useContext, useRef, useState } from 'react';
// import images
import DetImg from '../img/contact/detroit.png';
import { motion } from 'framer-motion';
import { transition1 } from '../transitions';
import { CursorContext } from '../context/CursorContext';
import emailjs from 'emailjs-com';

const Contact = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  const form = useRef();
  const [submitted, setSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    // Replace these with your actual EmailJS service details
    emailjs.sendForm('service_rrhxc5q', 'template_e4bgbwy', form.current, 'BDeU-UUrN18_lQKC9')
      .then((result) => {
          console.log(result.text);
          setSubmitted(true);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <motion.section
      initial={{ opacity: 0, y: '100%' }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: '100%' }}
      transition={transition1}
      className='section bg-[#FF4003]'
    >
      <div className='container mx-auto h-full'>
        <div className='flex flex-col lg:flex-row h-full items-center justify-start pt-36 gap-x-8 text-center lg:text-left'>
          {/* bg */}
          <motion.div
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '100%' }}
            transition={transition1}
            className='hidden lg:flex bg-[#eef7f9] absolute bottom-0 left-0 right-0 top-72 -z-10'
          ></motion.div>
          {/* text & form */}
          <div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            className='lg:flex-1 lg:pt-32 px-4'
          >
            <h1 className='h1'>CONTACT</h1>
            {submitted ? (
              <p className='mb-12'>Thanks! Your Message is Sent.</p>
            ) : (
              <form ref={form} onSubmit={sendEmail} className='flex flex-col gap-y-4'>
                <div className='flex gap-x-10'>
                  <input
                    className='outline-none border-b border-b-primaryc h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#28282B]'
                    type='text'
                    placeholder='NAME'
                    name="name"
                    required
                  />
                  <input
                    className='outline-none border-b border-b-primaryc h-[60px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#28282B]'
                    type='email'
                    placeholder='EMAIL'
                    name="email"
                    required
                  />
                </div>
                <textarea
                  className='outline-none border-b border-b-primaryc h-[100px] bg-transparent font-secondary w-full pl-3 placeholder:text-[#28282B]'
                  placeholder='MESSAGE'
                  name="message"
                  required
                />
                <button type="submit" className='btn mb-[30px] mx-auto lg:mx-0 self-start text-[#FF4003] ' id = 'btn1'>
                  SUBMIT
                </button>
              </form>
            )}
          </div>
          {/* image */}
          <motion.div
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '100%' }}
            transition={{ transition: transition1, duration: 1.5 }}
            className='lg:flex-1'
          >
            <img src={DetImg} alt='' />
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default Contact;
