import React, { useContext } from 'react';
// import icons
import {
  ImTwitter,
  ImLinkedin,
  ImGithub,
  ImMail2,
  ImPhone,
} from 'react-icons/im';
// import cursor context
import { CursorContext } from '../context/CursorContext';

const Socials = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);
  return (
    <div
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      className='hidden xl:flex ml-24'
    >
      <ul className='flex gap-x-4'>
        <li>
          <a href='https://www.linkedin.com/in/almoamenahmed/' target='_blank'>
            <ImLinkedin/>
          </a>
        </li>
        <li>
          <a href='https://github.com/almoamenahmed' target='_blank'>
            <ImGithub />
          </a>
        </li>
        <li>
          <a href='mailto:ajalmoam@umich.edu' target='_blank'>
            <ImMail2 />
          </a>
        </li>
        <li>
          <a href='tel:313-443-8691' target='_blank'>
            <ImPhone />
          </a>
        </li>
        <li>
          <a href='https://x.com/ajalmoam' target='_blank'>
            <ImTwitter />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Socials;
