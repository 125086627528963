import React, { useContext } from 'react';
// import images
import BufferImg from '../img/home/buffer.png';
// import link
import { Link } from 'react-router-dom';
// import motion
import { motion } from 'framer-motion';
// import transition
import { transition1 } from '../transitions';
// cursor context
import { CursorContext } from '../context/CursorContext';
// import ThreeText component
import ThreeText from '../components/ThreeText';
import * as THREE from 'three';


const Home = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition1}
      className='section'
    >
      <div className='container mx-auto h-full relative'>
        {/* text & img wrapper */}
        <div className='flex flex-col justify-center'>
          {/* text */}
          <motion.div
            initial={{ opacity: 0, y: '-50%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '-50%' }}
            transition={transition1}
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            className='w-full pt-36 pb-14 lg:pt-0 lg:pb-0 lg:w-auto z-10 lg:absolute flex flex-col justify-center items-center lg:items-start space-y-5'  // Updated
          >
            <h1 className='h1 text-[28px] sm:text-[54px] lg:text-[108px]'>
              SOFTWARE <br /> DEVELOPER
            </h1>
            <p id='p1' className='text-[18px] sm:text-[26px] lg:text-[36px] font-primary mb-2 sm:mb-4 lg:mb-12'>
              METRO DETROIT, MI
            </p>
            <a href="/ResumeAlmoamen.pdf" target="_blank" className="btn" id='btn1' >
              RESUME
            </a>
          </motion.div>

          {/* Right-side image & particles */}
          <div className='flex justify-end max-h-96 lg:max-h-max relative'>
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={transition1}
              className='relative lg:-right-40 overflow-hidden'
            >
              {/* Image */}
              <motion.img
                transition={transition1}
                src={BufferImg}
                alt=''
                className='w-full max-w-[300px] sm:max-w-[500px] lg:max-w-[800px]'  // Responsive image width
              />

            </motion.div>

            {/* Replace with ThreeText on the right */}
            <div className='lg:absolute lg:right-0 w-full lg:w-[400px] h-[200px] lg:h-[400px]'>
              <ThreeText />
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Home;