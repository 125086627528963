import React, { useContext } from 'react';
// import components
import Socials from './Socials';
import Logo from '../img/header/logo.png';
import MobileNav from './MobileNav';
// import link
import { Link } from 'react-router-dom';
// import cursor context
import { CursorContext } from '../context/CursorContext';

const Header = () => {
  const { mouseEnterHandler, mouseLeaveHandler } = useContext(CursorContext);

  return (
    <header className='fixed w-full px-[20px] sm:px-[30px] lg:px-[135px] z-30 h-[80px] sm:h-[100px] lg:h-[140px] flex items-center'>

      <div  className='flex flex-col lg:flex-row lg:items-center w-full justify-between'>
        {/* logo */}
        <Link
          id='logo'
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          to={'/'}
          className='max-w-[220px] pt-10'
        >
          <img src={Logo} alt='' />
        </Link>
        {/* nav - initially hidden - show on desktop mode */}
        <nav
          className='hidden sm:flex gap-x-10 font-semibold text-accent'
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
        >
          <Link
            to={'/'}
          >
            HOME
          </Link>
          <Link
            to={'/about'}
          >
            ABOUT
          </Link>
          <Link
            to={'/portfolio'}
          >
            PROJECTS
          </Link>
          <Link
            to={'/contact'}
          >
            CONTACT
          </Link>
        </nav>
      </div>
      {/* socials */}
      <Socials />
      {/* mobile nav */}
      <MobileNav />
    </header>
  );
};

export default Header;